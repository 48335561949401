export const setCSSVariables = (config: any) => {
  const root = document.documentElement;

  const properties = {
    cleeng_general_font_color: config.cleeng_general_font_color,
    cleeng_coupon_input_color: config.cleeng_coupon_input_color,
    cleeng_coupon_button_color: config.cleeng_coupon_button_color,
    cleeng_error_color: config.cleeng_error_color,
    cleeng_offer_card_title_font_color:
      config.cleeng_offer_card_title_font_color,
    cleeng_offer_card_description_font_color:
      config.cleeng_offer_card_description_font_color,
    cleeng_offer_card_price_font_color:
      config.cleeng_offer_card_price_font_color,
    cleeng_offer_card_icon_font_color: config.cleeng_offer_card_icon_font_color,
    cleeng_offer_card_icon_bg_color: config.cleeng_offer_card_icon_bg_color,
    cleeng_summary_item_font_color: config.cleeng_summary_item_font_color,
    cleeng_summary_total_font_color: config.cleeng_summary_total_font_color,
    cleeng_card_input_color: config.cleeng_card_input_color,
    cleeng_coupon_button_bg_color: config.cleeng_coupon_button_bg_color,
    cleeng_pay_button_bg_color: config.cleeng_pay_button_bg_color,
  };

  Object.keys(properties).forEach((property) => {
    // @ts-ignore
    root.style.setProperty(`--${property}`, properties[property]);
  });
};
