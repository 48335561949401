import React, { Suspense, lazy, useRef } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import { useSearchParams } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import { setCSSVariables } from './styles/utils';
import { v4 as uuidv4 } from 'uuid';
import { track } from '~/services/analytics/index.client.ts';
import { EventName, getPurchaseAnalyticData } from '~/utils/analytics.tsx';
import { isGaEnabled } from '~/services/analytics/index.client.ts';

function addQueryParam(url: string = '/', param: string, value: string) {
  // Create a URL object
  let urlObj = new URL(url, window.location.origin);

  // Get the existing search parameters
  let searchParams = new URLSearchParams(urlObj.search);

  // Add the new query parameter
  searchParams.set(param, value);

  // Set the updated search parameters back to the URL object
  urlObj.search = searchParams.toString();

  // Return the updated URL
  return urlObj.toString();
}

const isValidURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

// @ts-ignore
const Purchase = lazy(() =>
  // @ts-ignore
  import('@cleeng/mediastore-sdk').then((module) => ({
    default: module.Purchase,
  }))
) as React.ComponentType<{
  offerId: string;
  onSuccess: () => Promise<void>;
  couponCode: string | null;
}>;

const Provider = lazy(() =>
  import('react-redux').then((module) => ({ default: module.Provider }))
);

interface CleengProviderProps {
  config: any;
  localizations: any;
}

const getCleengConfig = async () => {
  try {
    // @ts-ignore
    const module = await import('@cleeng/mediastore-sdk');
    const Config = module.Config;
    const Store = module.store;
    return { Config, Store };
  } catch (err) {
    console.error('Failed to load Config', err);
    throw err;
  }
};

export default function CleengProvider({
  config,
  localizations,
}: CleengProviderProps) {
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get('productId');
  const couponCode = searchParams.get('couponCode');

  const [loading, setLoading] = React.useState(true);
  const order = useRef({});

  const storeRef = React.useRef(null);

  const { authData, successRedirectLink, analyticsTrackData, screen, baseURL } =
    useLoaderData<any>();

  const {
    cleeng_publisher_id,
    cleeng_environment,
    card,
    paypal,
    googlepay,
    applepay,
    paypal_success_url,
    paypal_cancel_url,
    paypal_error_url,
    hidden_payments,
  } = config?.general;

  const {
    cleeng_general_font_color,
    cleeng_error_color,
    cleeng_success_color,
    cleeng_primary_color,
    cleeng_loader_color,
  } = config?.styles;

  const handleSuccess = async () => {
    try {
      if (!isGaEnabled()) {
        handleRedirect();
        return;
      }
    } catch (e) {
      console.error(e);
      handleRedirect();
    }
  };

  const handleRedirect = () => {
    window.location.href =
      addQueryParam(successRedirectLink, 'cb', uuidv4()) || '/';
  };

  const getPaymentMethods = () => {
    const methods: { [key: string]: any } = {
      card,
      googlepay,
      applepay,
    };

    return Object.keys(methods).filter((method) => methods[method]);
  };

  const initializeCleeng = React.useCallback(async () => {
    const { Config, Store } = await getCleengConfig();

    storeRef.current = Store;

    const paymentMethods = getPaymentMethods();

    setCSSVariables(config?.styles);

    Config.setEnvironment(cleeng_environment);
    Config.setPublisher(cleeng_publisher_id);
    Config.setJWT(authData?.access_token);
    Config.setRefreshToken(authData?.refresh_token);
    Config.setTheme({
      fontColor: cleeng_general_font_color,
      successColor: cleeng_success_color,
      primaryColor: cleeng_primary_color,
      loaderColor: cleeng_loader_color,
      errorColor: cleeng_error_color,
    });

    Config.setVisibleAdyenPaymentMethods(paymentMethods);

    if (!paypal) {
      Config.setHidePayPal();
    }

    if (hidden_payments && hidden_payments.length > 0) {
      const hiddenPayments = String(hidden_payments)
        .split(',')
        .map((payment: string) => Number(payment));

      Config.setHiddenPaymentMethods(hiddenPayments);
    }

    setLoading(false);
  }, [authData, config, config?.general, config?.styles]);

  const getPayPalUrl = (
    eventStatus: EventName,
    redirectUrl: string,
    aditionalParams: any
  ): string => {
    return `${baseURL}/paypal-purchase?paypalPurchaseStatus=${eventStatus}&redirectUrl=${encodeURIComponent(
      redirectUrl
    )}&${aditionalParams.toString()}`;
  };

  const setCheckoutPayPalUrls = async (trackProperties: any) => {
    const { Config } = await getCleengConfig();

    const { origin } = new URL(window.location.href);

    const successRedirectUrl = isValidURL(paypal_success_url)
      ? paypal_success_url
      : addQueryParam(successRedirectLink, 'cb', uuidv4())
      ? `${origin}${successRedirectLink}`
      : origin;
    const cancelRedirectUrl = paypal_cancel_url || window.location.href;
    const errorRedirectUrl = paypal_error_url || window.location.href;

    const timestamp = Date.now().toString();
    const params = new URLSearchParams();
    params.append('trackProperties', JSON.stringify(trackProperties));
    params.append('timestamp', timestamp);

    const successUrl = getPayPalUrl(
      'purchase_success',
      successRedirectUrl,
      params
    );
    const cancelUrl = getPayPalUrl(
      'purchase_cancelled',
      cancelRedirectUrl,
      params
    );
    const errorUrl = getPayPalUrl('purchase_failed', errorRedirectUrl, params);

    Config.setCheckoutPayPalUrls({
      successUrl,
      cancelUrl,
      errorUrl,
    });
  };

  const handleEvent = React.useCallback(
    (eventType: any, evt?: any, callback?: any) => {
      const eventData = getRecentStoreValues(evt);

      const { eventName, trackData } = getPurchaseAnalyticData(
        eventType,
        analyticsTrackData,
        {
          ...eventData,
          purchaseType: eventData?.offer?.offerV2?.type,
        }
      );

      const trackProperties = {
        ...trackData,
        screen_name: screen?.name,
        event_callback: callback ? callback : null,
      };

      if (eventType === 'purchase_triggered') {
        order.current = evt;

        if (paypal) {
          setCheckoutPayPalUrls(trackProperties);
        }
      }

      track(eventName, trackProperties);
    },
    [analyticsTrackData, screen?.name, paypal]
  );

  const handleAnalytics = () => {
    const onPurchaseLoaded = (evt: any) =>
      handleEvent('purchase_triggered', evt);
    const onPurchaseSuccess = (evt: any) => {
      handleEvent('purchase', evt);
      handleEvent('purchase_success', evt, handleRedirect);
    };
    const onPurchaseFailed = (evt: any) => handleEvent('purchase_failed');

    window.addEventListener('MSSDK:Purchase-loaded', onPurchaseLoaded);
    window.addEventListener('MSSDK:purchase-successful', onPurchaseSuccess);
    window.addEventListener('MSSDK:purchase-failed', onPurchaseFailed);

    return () => {
      window.removeEventListener('MSSDK:Purchase-loaded', onPurchaseLoaded);
      window.removeEventListener(
        'MSSDK:purchase-successful',
        onPurchaseSuccess
      );
      window.removeEventListener('MSSDK:purchase-failed', onPurchaseFailed);
    };
  };

  const getRecentStoreValues = (event: any) => {
    return (
      event?.detail?.order || (storeRef?.current as any)?.getState()?.offer
    );
  };

  React.useEffect(() => {
    const unsubscribe = handleAnalytics();

    initializeCleeng();
    return () => {
      unsubscribe();
      handleEvent('purchase_cancelled', order.current);
    };
  }, []);

  return (
    <ClientOnly fallback={<></>}>
      {() => {
        if (loading) {
          return null;
        }

        return (
          <Suspense fallback={<></>}>
            <Provider
              // @ts-ignore
              store={storeRef.current}
            >
              <div className="storefront-container mx-auto h-screen w-[100%] max-w-[1000px] flex-col lg:flex-row">
                {
                  <Suspense fallback={<></>}>
                    <Purchase
                      offerId={offerId as string}
                      onSuccess={handleSuccess}
                      couponCode={couponCode}
                    />
                  </Suspense>
                }
              </div>
            </Provider>
          </Suspense>
        );
      }}
    </ClientOnly>
  );
}
